<template>
  <b-modal
    id="modal-department-of-health-setting"
    ref="refModalDepartmentOfHealthSetting"
    title="Cài đặt"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onShow"
    @ok="onSubmit"
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner

        class="text-center"
        variant="secondary"
      />
    </div>
    <!-- Body -->
    <validation-observer
      v-else
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="my-2">
          <b-col cols="12">
            <span>Cho phép người dân tỉnh khác gặp bác sĩ trong tỉnh</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="is_allow_another_province_meet"
                name="is_allow_another_province_meet"
                :value="true"
                class="custom-control-primary"
              >
                Bật
              </b-form-radio>

              <b-form-radio
                v-model="is_allow_another_province_meet"
                name="is_allow_another_province_meet"
                :value="false"
                class="custom-control-primary"
              >
                Tắt
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col cols="12">
            <span>Cho phép người dân trong tỉnh gặp bác sĩ liên tuyến</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="is_allow_inter_level"
                name="is_allow_inter_level"
                :value="true"
                class="custom-control-primary"
              >
                Bật
              </b-form-radio>

              <b-form-radio
                v-model="is_allow_inter_level"
                name="is_allow_inter_level"
                :value="false"
                class="custom-control-primary"
              >
                Tắt
              </b-form-radio>
            </div>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormRadio,
  BSpinner,
} from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    BFormRadio,
    BSpinner,
  },
  props: {
    doctor: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: 'modal-change-password',
    },
  },
  data() {
    return {
      required,
      isLoading: false,
      is_allow_another_province_meet: true,
      is_allow_inter_level: true,
    };
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      useJwt.getDepartmentOfHealthSetting()
        .then(response => {
          this.isLoading = false;
          this.is_allow_another_province_meet = response.data.is_allow_another_province_meet;
          this.is_allow_inter_level = response.data.is_allow_inter_level;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },
    onShow() {
      this.fetchData();
    },

    onSubmit() {
      useJwt.updateDepartmentOfHealthSetting({ is_allow_another_province_meet: this.is_allow_another_province_meet, is_allow_inter_level: this.is_allow_inter_level }).then(() => {
        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: `Thông báo`,
            icon: 'UserIcon',
            variant: 'success',
            text: `Thông tin đã được cập nhật thành công`,
          },
        });
      })
        .catch(error => {
          if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Thông báo',
                text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
  },

  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <span class="font-weight-bolder text-primary">HỆ THỐNG HỖ TRỢ TƯ VẤN KHÁM CHỮA BỆNH TỪ XA - VTELEHEALTH</span>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <notification-dropdown /> -->
      <!-- <locale /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import UserDropdown from './UserDropdown.vue';
import Locale from './Locale.vue';

export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    DarkToggler,
    Locale,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
